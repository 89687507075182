
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as teamgw6mof7n7nRA3eN2EU8evPVMwTK_w8ULCcGDTlzZgUEMeta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/team.vue?macro=true";
import { default as index6FImpaGGqw_45b95bqJEAfAOXNMLL5kqMyQ91DgJtEmC0Meta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/index.vue?macro=true";
import { default as showcasedCwaacsf__PHGZL9cXCo7jg2N_2jkEVyQjz4cr0_tjoMeta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/showcase.vue?macro=true";
import { default as templatesEHYOkNeZomoXB5sWVPYsKPLFKVvxMsUn0Ot3KvUOXV0Meta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/templates.vue?macro=true";
import { default as indexThx1Xx7mYj07JX4VD6_6C_WALJj22caCMGMvarF18p8Meta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/blog/index.vue?macro=true";
import { default as design_45kiteqF19tJFHkI0ADZ_45fDOx5clkzO_45vWWHYfHdwLrDz6_45sMeta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/design-kit.vue?macro=true";
import { default as jobs102yXMIAvFnBJ9v94rEPtM_45BkHY98Z5idykf754nKjIMeta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/enterprise/jobs.vue?macro=true";
import { default as support8n6BPZ8fLSz9KxrwJNIOSlFnFP4GDU_wAUXcGjIAV5UMeta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/enterprise/support.vue?macro=true";
import { default as sponsorsIpyEt_HJkL3iv_BtzDzTL9mLLz2IzxtkANb1T_450udFgMeta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/enterprise/sponsors.vue?macro=true";
import { default as indexX5sAgMN3hkJJQxgy5ucLgayKu_452OuSw6MKGgwLmn1JQMeta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/enterprise/agencies/index.vue?macro=true";
import { default as _91slug_93QT0g_KZBms5x5SAG74OEPKRvX5U0ukDp1kyivNgMk7cMeta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/enterprise/agencies/[slug].vue?macro=true";
import { default as enterpriseY5dxxrkFPmDc804T6MmG0GTswgplb_45JT3OsvRFseJAAMeta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/enterprise.vue?macro=true";
import { default as newslettercvuldkenPM5yg0Hi_45RHZv8jrtmRehDsoIyDZszUucLwMeta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/newsletter.vue?macro=true";
import { default as _91slug_93nIVj6hQPbqqfbenHO7QBQJMJ_MKYOVwJp6DIdZlnR5QMeta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/blog/[slug].vue?macro=true";
import { default as index0ccEl3Ccas_DHyaC49uCcuQPXRacJqJnnA9PpceXACkMeta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/deploy/index.vue?macro=true";
import { default as _91slug_93RxmdpwxdqhAEDr0cD_gWkkQsaca8sC5qaD6GkLuHoDkMeta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/deploy/[slug].vue?macro=true";
import { default as index7P3fCFH6DDzbuKga_6Mpn3hG0QGTACQhMJP2IPxW9ngMeta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/modules/index.vue?macro=true";
import { default as video_45coursesqi1R23nxbrM89LMU54emUpWbvn9icXVdXE5y9blzaLwMeta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/video-courses.vue?macro=true";
import { default as _91_46_46_46slug_938zrru4qJVQqkso_xmn5NguERGhHdyUJ6OUSAWYGdFVIMeta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/docs/[...slug].vue?macro=true";
import { default as _91slug_93S2AUtu1SZo9BilkrOIQu06JK_aGzJxdWzvAFarNmU6QMeta } from "/home/runner/work/nuxt.com/nuxt.com/app/pages/modules/[slug].vue?macro=true";
import { default as component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta } from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.1_db0@0.3.1__b2c91da27c93005bdf36c3d6c639610f/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg } from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.1_db0@0.3.1__b2c91da27c93005bdf36c3d6c639610f/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "team",
    path: "/team",
    meta: teamgw6mof7n7nRA3eN2EU8evPVMwTK_w8ULCcGDTlzZgUEMeta || {},
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/team.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index6FImpaGGqw_45b95bqJEAfAOXNMLL5kqMyQ91DgJtEmC0Meta || {},
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/index.vue")
  },
  {
    name: "showcase",
    path: "/showcase",
    meta: showcasedCwaacsf__PHGZL9cXCo7jg2N_2jkEVyQjz4cr0_tjoMeta || {},
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/showcase.vue")
  },
  {
    name: "templates",
    path: "/templates",
    meta: templatesEHYOkNeZomoXB5sWVPYsKPLFKVvxMsUn0Ot3KvUOXV0Meta || {},
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/templates.vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexThx1Xx7mYj07JX4VD6_6C_WALJj22caCMGMvarF18p8Meta || {},
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/blog/index.vue")
  },
  {
    name: "design-kit",
    path: "/design-kit",
    meta: design_45kiteqF19tJFHkI0ADZ_45fDOx5clkzO_45vWWHYfHdwLrDz6_45sMeta || {},
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/design-kit.vue")
  },
  {
    name: "enterprise",
    path: "/enterprise",
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/enterprise.vue"),
    children: [
  {
    name: "enterprise-jobs",
    path: "jobs",
    meta: jobs102yXMIAvFnBJ9v94rEPtM_45BkHY98Z5idykf754nKjIMeta || {},
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/enterprise/jobs.vue")
  },
  {
    name: "enterprise-support",
    path: "support",
    meta: support8n6BPZ8fLSz9KxrwJNIOSlFnFP4GDU_wAUXcGjIAV5UMeta || {},
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/enterprise/support.vue")
  },
  {
    name: "enterprise-sponsors",
    path: "sponsors",
    meta: sponsorsIpyEt_HJkL3iv_BtzDzTL9mLLz2IzxtkANb1T_450udFgMeta || {},
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/enterprise/sponsors.vue")
  },
  {
    name: "enterprise-agencies",
    path: "agencies",
    meta: indexX5sAgMN3hkJJQxgy5ucLgayKu_452OuSw6MKGgwLmn1JQMeta || {},
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/enterprise/agencies/index.vue")
  },
  {
    name: "enterprise-agencies-slug",
    path: "agencies/:slug()",
    meta: _91slug_93QT0g_KZBms5x5SAG74OEPKRvX5U0ukDp1kyivNgMk7cMeta || {},
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/enterprise/agencies/[slug].vue")
  }
]
  },
  {
    name: "newsletter",
    path: "/newsletter",
    meta: newslettercvuldkenPM5yg0Hi_45RHZv8jrtmRehDsoIyDZszUucLwMeta || {},
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/newsletter.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93nIVj6hQPbqqfbenHO7QBQJMJ_MKYOVwJp6DIdZlnR5QMeta || {},
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/blog/[slug].vue")
  },
  {
    name: "deploy",
    path: "/deploy",
    meta: index0ccEl3Ccas_DHyaC49uCcuQPXRacJqJnnA9PpceXACkMeta || {},
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/deploy/index.vue")
  },
  {
    name: "deploy-slug",
    path: "/deploy/:slug()",
    meta: _91slug_93RxmdpwxdqhAEDr0cD_gWkkQsaca8sC5qaD6GkLuHoDkMeta || {},
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/deploy/[slug].vue")
  },
  {
    name: "modules",
    path: "/modules",
    meta: index7P3fCFH6DDzbuKga_6Mpn3hG0QGTACQhMJP2IPxW9ngMeta || {},
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/modules/index.vue")
  },
  {
    name: "video-courses",
    path: "/video-courses",
    meta: video_45coursesqi1R23nxbrM89LMU54emUpWbvn9icXVdXE5y9blzaLwMeta || {},
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/video-courses.vue")
  },
  {
    name: "docs-slug",
    path: "/docs/:slug(.*)*",
    meta: _91_46_46_46slug_938zrru4qJVQqkso_xmn5NguERGhHdyUJ6OUSAWYGdFVIMeta || {},
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/docs/[...slug].vue")
  },
  {
    name: "modules-slug",
    path: "/modules/:slug()",
    meta: _91slug_93S2AUtu1SZo9BilkrOIQu06JK_aGzJxdWzvAFarNmU6QMeta || {},
    component: () => import("/home/runner/work/nuxt.com/nuxt.com/app/pages/modules/[slug].vue")
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/getting-started",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/guide/concepts",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/guide/directory-structure",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/guide/going-further",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/guide/going-further/edge-release-channel",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/bridge",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/migration",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/api/components",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/api/composables",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/api/utils",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/api/kit",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/api/commands",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/api/advanced",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/api/configuration/nuxt-config",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/examples",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/examples/features",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/examples/routing",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/examples/advanced",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/examples/experimental",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/community",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/community/nuxt-community",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/guide/recipes",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/guide/best-practices",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/docs/guide/going-further/custom-routing",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  },
  {
    name: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCgMeta?.name,
    path: "/support/us",
    component: component_45stub3BzjfQx_45fUeP_45eJ3rpiNorZffu7m8GAVOf_456oDuHdCg
  }
]